import "./Orphans.css";
import Education from "./sections/education/Education";
import Support from "./sections/support/Support";

export default function Orphans() {
  return (
    <div>
      <Education />
      <Support />
    </div>
  );
}
